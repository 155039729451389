#navvy {
    background: linear-gradient(90deg, #FD84A3 0%, #FCCB46 100%);
}

#navvy .nav-link:hover {
    color: white;
}

#navvy .navbar-brand:hover {
    color: white;
}
