@media (max-width: 768px) {
.row.align-items-center {
      flex-direction: column; /* Change flex direction for smaller screens */
      text-align: center; /* Center-align the text */
    }
  
.col-4.w-25 {
      width: 100%; /* Take up full width on smaller screens */
    }

.baby-toy-img {
        align-items: center;
        height: auto;
        margin-bottom: auto;
        margin-left: 10px;
    }
}

.baby-toy-img {
    align-items: center;
    height: auto;
    margin-bottom: auto;
}
.icon {
    font-size: 3rem; 
    color: #000; 
    margin-right: 10px; 
}
.project-link {
    text-decoration: none;
    background: linear-gradient(90deg, #FD84A3 0%, #FCCB46 100%);
    background-clip: text; 
    -webkit-background-clip: text; 
    color: transparent; 
    transition: background 0.3s;
}

.project-link:hover {
    color: initial; 
    background: none; 
}

.projects {
    border: solid #ed8ba2d9 2px;
    margin-bottom: 0.5rem;
    border-radius: 10px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
}

.font {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
}

  